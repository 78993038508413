import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfigService } from 'src/services/api-config.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private apiConfig: ApiConfigService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url
        const token = this.apiConfig.getToken()
        const isApiUrl = request.url.startsWith('https://api.defeatedrecords.com');

        //set token for api urls
        if (!!token && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
        }
        return next.handle(request);
    }
}