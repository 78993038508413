<div class="flex items-center justify-center w-screen h-screen">
  <div class="px-40 py-20 bg-white rounded-md shadow-xl">
    <div class="flex flex-col items-center">
      <h1 class="font-bold text-gray-600 text-9xl">404</h1>
      <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
        Page not found
      </h6>
      <p class="mb-8 text-center text-gray-500 md:text-lg">
        The page you're looking for doesn't exist.
      </p>
      <a href="/" class="px-6 py-2 text-sm font-semibold text-gray-900 bg-dr-orange-700 rounded-md">Go home</a>
    </div>
  </div>
</div>
