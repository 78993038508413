import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ApiConfigService } from 'src/services/api-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    public auth: AuthService,
    private router: Router,
    private apiConfig: ApiConfigService
  ){
    this.auth.idTokenClaims$.subscribe((claims)=>{
      if(!!claims){
        this.apiConfig.setToken(claims.__raw);
      }
    })
  }

  gotoManage(){
    this.router.navigate(['manage'])
  }

  logout(): void {
    // Call this to log the user out of the application
    this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
  }
}
