import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  private token?: string;

  constructor(
    private http: HttpClient
  ) { }

  setToken(token:string){
    this.token = token;
  }

  getToken(){
    return this.token;
  }
}
