<header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a href="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"  >
      <img alt="Defeated Records Logo" src="assets/DR_logo_no_text_circle.png" class="w-10 h-10 text-white rounded-full">
      <span class="ml-3 text-xl">Defeated Records</span>
    </a>
    <div class="ml-auto" *ngIf="auth.isAuthenticated$ | async">
      <button class="mr-4" (click)="gotoManage()">
        Manage
      </button>
      <button (click)="logout()">
        Log out
      </button>
    </div>
  </div>
</header>

<ng-container *ngIf="!(auth.isLoading$ | async)">

  <router-outlet></router-outlet>
</ng-container>
